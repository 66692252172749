import useBody from '~/composables/dom/useBody';
import type { TWebsiteConfig } from '~/typings/types/website-config.types';
import useDisableScrollbar from '~/composables/dom/useDisableScrollbar';

export default function useMenu() {
  const { toggleBodyClass } = useBody();
  const { compensateScrollbar, deCompensateScrollbar } = useDisableScrollbar();
  const websiteConfig = useState<TWebsiteConfig>('config');

  const toggleMenuDesktopHandler = () => {
    websiteConfig.value.menusState.desktop = !websiteConfig.value.menusState.desktop;
  };

  const closeMenuDesktopHandler = () => {
    websiteConfig.value.menusState.desktop = false;
  };

  const toggleMenuMobileHandler = () => {
    websiteConfig.value.menusState.mobile = !websiteConfig.value.menusState.mobile;
  };

  const closeMenuMobileHandler = () => {
    websiteConfig.value.menusState.mobile = false;
  };

  watch(
    () => websiteConfig.value.menusState.desktop,
    () => {
      websiteConfig.value.menusState.desktop || websiteConfig.value.dialogs.forms.state
        ? compensateScrollbar()
        : deCompensateScrollbar();

      websiteConfig.value.menusState.desktop
        ? toggleBodyClass('add', 'open-menu')
        : toggleBodyClass('remove', 'open-menu');
    },
  );

  watch(
    () => websiteConfig.value.menusState.mobile,
    () => {
      websiteConfig.value.menusState.mobile || websiteConfig.value.dialogs.forms.state
        ? compensateScrollbar()
        : deCompensateScrollbar();
    },
  );

  return {
    toggleMenuDesktopHandler,
    closeMenuDesktopHandler,
    toggleMenuMobileHandler,
    closeMenuMobileHandler,
  };
}
